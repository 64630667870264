<template>
  <div>
    <b-card>
      <div class="table-wrap">
        <div class="filter-wrap flex-between">
          <div class="flex-start search-filter-wrap">
            <el-input
                v-model="dataForm.searchStr"
                width="180"
                placeholder="请输入内容"
                clearable
                @clear="handleFilter"
            />
            <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter"
            >
              搜索
            </el-button>
          </div>
        </div>
        <el-table
            v-loading="dataListLoading"
            border
            fit
            highlight-current-row
            :data="dataList"
            stripe
            style="width: 100%"
        >
          <el-table-column
              prop="serialNumber"
              :show-overflow-tooltip="true"
              label="编号"
          />
          <el-table-column
              prop="receivedUser"
              :show-overflow-tooltip="true"
              label="接收人"
          >
          </el-table-column>
          <el-table-column
              prop="receivedTime"
              :show-overflow-tooltip="true"
              width="145px"
              label="接收时间"
          />
          <el-table-column
              prop="userName"
              :show-overflow-tooltip="true"
              label="主体姓名"
          />
          <el-table-column
              prop="rightTypeStr"
              :show-overflow-tooltip="true"
              label="权力类型"
          />
          <el-table-column
              prop="productNames"
              :show-overflow-tooltip="true"
              label="涉及业务"
          />
          <el-table-column
              prop="flowName"
              :show-overflow-tooltip="true"
              label="流程模板"
          />
          <el-table-column
              prop="status"
              :show-overflow-tooltip="true"
              label="状态"
          >
            <template v-slot:="scope">
              {{  statusMap[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              width="120px"
          >
            <template slot-scope="{row}">
              <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="toUpdate(row.id)"
              >
                <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="deleteHandle(row.id)"
              >
                <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
              <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="toDetail(row.id)"
              >
                <el-tooltip
                    content="查看"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-document" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>

      </div>
    </b-card>
    <b-card title="评估模板">
      <b-row
          v-if="cardList.length>0"
          v-loading="cardLoading"
          class="match-height"
      >
        <b-col
            v-for="(item,index) in cardList"
            :key="index"
            xl="3"
            lg="3"
            md="3"
        >
          <div
              class="card-list mb-1"
              @click="toSave(item)"
          >
            <div class="card-list-header">
              <h4 class="card-list-title overflow-ellipsis px-5 ">
                {{ item.name }}
              </h4>
            </div>
            <div class="card-list-body">
              <el-tooltip
                  placement="top"
                  effect="light"
                  :content="item.explain"
                  :disabled="item.explain.length <= 40"
              >
                <span class="overflow-ellipsis card-list-abstract">{{ item.explain }}</span>
              </el-tooltip>
            </div>
            <div class="text-right card-list-operate">
              <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="toSave(item)"
              >
              <span class="font-smaller-3 flex-between">
                <i class="el-icon-s-promotion" />
                <span class="f12">新建</span>
              </span>
              </el-link>
            </div>
          </div>
        </b-col>
      </b-row>
      <el-empty
          v-if="cardList.length===0 && !loading"
          description="暂无数据"
      />
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: window.g.baseUrl,
      dataListLoading: false,
      cardLoading: false,
      dataList: [],
      cardList: [],
      total: 0,
      dataForm: {
        order: '', // 排序，asc／desc
        orderField: '', // 排序，字段
        pageNo: 1, // 当前页码
        pageSize: 10, // 每页数
        searchStr: '',
      },
      statusMap: {
        1: '未开始',
        2: '处理中',
        3: '处理完成',
      },
    }
  },
  created() {
    this.getFlowList()
    this.getList()
  },
  methods: {
    init() {
      const { pageNo } = this.$route.query
      const { pageSize } = this.$route.query
      if (pageNo !== undefined && pageNo !== '') {
        this.dataForm.pageNo = Number(pageNo)
      }
      if (pageSize !== undefined && pageSize !== '') {
        this.dataForm.pageSize = Number(pageSize)
      }
    },
    getList() {
      this.dataListLoading = true
      this.$http.post('/custom/request/page', this.dataForm).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          this.total = 0
          this.$message.error(res.msg)
        } else {
          this.dataList = res.data.list
          this.total = res.data.total
        }
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    getFlowList() {
      this.cardLoading = true
      this.$http.get('/custom/request/flow/list').then(({ data: res }) => {
        this.cardLoading = false
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.cardList = res.data
        }
      }).catch(() => {
        this.cardLoading = false
      })
    },
    handleFilter() {
      this.dataForm.pageNo = 1
      this.getList()
    },
    toSave(item) {
      this.$router.push({
        path: '/custom/request/edit',
        query: {
          flowId: item.id,
          pageNo: this.dataForm.pageNo,
          pageSize: this.dataForm.pageSize,
        },
      })
    },
    toUpdate(id) {
      this.$router.push({
        path: '/custom/request/edit',
        query: {
          id,
          pageNo: this.dataForm.pageNo,
          pageSize: this.dataForm.pageSize,
        },
      })
    },
    deleteHandle(id) {
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.dataListLoading = true
        this.$http.delete(`/custom/request/delete?id=${id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.getList()
              },
            })
          }
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    toDetail(id) {
      this.$router.push({
        path: '/custom/request/detail',
        query: {
          id,
          pageNo: this.dataForm.pageNo,
          pageSize: this.dataForm.pageSize,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
